import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  apiRefreshSignedUrl,
  apiFetchSignedUrl,
  selectSignedUrl,
  selectHasExpired,
} from "../reduxPie/signedImageURLSlice";

const SignedImage = ({
  imageKey,
  altText = "Image",
  className = "",
  style,
  ...props
}) => {
  const dispatch = useDispatch();
  const signedUrl = useSelector((state) => selectSignedUrl(state, imageKey));
  const hasExpired = useSelector((state) => selectHasExpired(state, imageKey));
  const isLoading = useSelector((state) => state.signedImageURLs.isLoading);

  useEffect(() => {
    // Only fetch if we don't have a URL and we're not already loading
    if (!signedUrl && !isLoading) {
      dispatch(apiFetchSignedUrl({ imageKey }));
    }
    // Only refresh if we have a URL that has expired and we're not loading
    else if (signedUrl && hasExpired && !isLoading) {
      dispatch(apiRefreshSignedUrl({ imageKey }));
    }
  }, [signedUrl, hasExpired, isLoading, dispatch, imageKey]);

  return (
    <img
      src={signedUrl || ""}
      alt={altText}
      className={className}
      style={style}
      {...props}
    />
  );
};

export default React.memo(SignedImage);