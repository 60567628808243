import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  apiFindAllSites,
  apiFindAssignedSites,
} from "../../reduxPie/sitesSlice";
import { apiFindAllForms } from "../../reduxPie/formsSlice";
import {
  apiFindAllReports,
  apiFindReportsByDateRange,
} from "../../reduxPie/reportsSlice";
import { apiFindAllUsers } from "../../reduxPie/usersSlice";
import { apiFindAllEvents } from "../../reduxPie/eventsSlice";
import { apiFindAllClients } from "../../reduxPie/clientsSlice";
import { apiFindAllByType } from "../../reduxPie/personsSlice";
import DashboardCard from "./components/dashBoardCard";
import { DateInput } from "../../components/datePicker/DateInput";
import moment from "moment/moment";
import { Loading } from "../../components/Loading";

export function Home() {
  const [toDate, setToDate] = useState(() => new Date()); // Current date and time

  const [fromDate, setFromDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 1); // Set to one day earlier
    return date;
  });
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.selected);
  const eventsSlice = useSelector((state) => state.events);
  const formsSlice = useSelector((state) => state.forms);
  const clientsSlice = useSelector((state) => state.clients);
  const sitesSlice = useSelector((state) => state.sites);
  const usersSlice = useSelector((state) => state.users);
  const reportsSlice = useSelector((state) => state.reports);
  const { readinessLogsLoading } = useSelector((state) => state.reports);
  const personsSlice = useSelector((state) => state.persons);

  // AUTO FETCH MAIN DATA
  useEffect(() => {
    const personTypes = personsSlice?.data?.map((x) => x.type);
    if (!eventsSlice?.data) dispatch(apiFindAllEvents());
    if (!formsSlice?.data) dispatch(apiFindAllForms());
    if (!reportsSlice?.data) dispatch(apiFindAllReports());
    if (reportsSlice?.reportsByDateRange.length === 0)
      dispatch(
        apiFindReportsByDateRange({
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString(),
          eventName: "Readiness Log",
        })
      );
    if (!usersSlice?.data) dispatch(apiFindAllUsers());
    if (!clientsSlice?.data) dispatch(apiFindAllClients());
    if (!personTypes?.includes("Pursuer"))
      dispatch(apiFindAllByType("Pursuer"));
    if (!sitesSlice?.data?.length)
      loggedIn?.assignedClients?.length
        ? dispatch(apiFindAssignedSites(loggedIn.assignedClients))
        : dispatch(apiFindAllSites());
  }, []);

  useEffect(() => {
    if (reportsSlice?.reportsByDateRange?.length) {
      const groupedArray = Object.values(
        reportsSlice?.reportsByDateRange?.reduce((acc, item) => {
          if (!acc[item.site?._id]) {
            acc[item.site?._id] = [];
          }
          acc[item.site?._id].push(item);
          return acc;
        }, {})
      );
      const sortedArray = groupedArray.sort((a, b) => {
        const nameA = a[0]?.client?.name?.toLowerCase() || "";
        const nameB = b[0]?.client?.name?.toLowerCase() || "";
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

      setData(sortedArray);
    }
  }, [JSON.stringify(reportsSlice?.reportsByDateRange)]);

  const handleLoadData = () => {
    dispatch(
      apiFindReportsByDateRange({
        fromDate: fromDate,
        toDate: toDate,
        eventName: "Readiness Log",
      })
    );
  };

  const getPhoneNo = (item) => {
    return item?.match(/(\(?\d{3}\)?[\s\-\.]?\d{3}[\s\-\.]?\d{4})/g) || "-";
  };

  const filteredData = data
    ?.map((group) => {
      if (!search) {
        return group;
      }
      const filteredGroup = group?.filter((item) => {
        const clientName = item?.client?.name?.toLowerCase() || "";
        const siteName = item?.site?.name?.toLowerCase() || "";
        const officerName = item?.createdBy?.name?.toLowerCase() || "";
        const searchTerm = search.toLowerCase();
        return (
          clientName.includes(searchTerm) ||
          siteName.includes(searchTerm) ||
          officerName.includes(searchTerm)
        );
      });
      return filteredGroup;
    })
    .filter((group) => group.length > 0);

  if (readinessLogsLoading) return <Loading title="Readiness Logs" />;
  return (
    <div
      className="d-flex flex-fill flex-column bg-white"
      style={{ padding: 16 }}
    >
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2 className="mb-2">Readiness Dashboard</h2>
         <div className="text-muted">{`${moment(fromDate).format(
          "MM/DD/YYYY hh:mm A"
        )} - ${moment(toDate).format("MM/DD/YYYY hh:mm A")}`}</div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <input
          type="text"
          className="form-control me-2 searchInput"
          placeholder="Search by client, site and officer"
          value={search}
          onChange={(e) => setSearch(e?.target?.value)}
        />
        <div className="d-flex gap-2">
          <DateInput
          date={fromDate}
          onChange={(e) => setFromDate(e)}
        />
        <DateInput date={toDate} onChange={(e) => setToDate(e)} maxDate={new Date()}/>
        </div>
        <button
          className="btn btn-sm btn-success border-2 border-light"
          onClick={handleLoadData}
        >
          Search
        </button>
      </div>

      {filteredData?.map((item, groupIndex) => (
        <div className="cardContainer" key={groupIndex}>
          <div className="mb-4 infoCard">
            <h5 className="fw-bold">{`${item[0]?.client?.name}, ${item[0]?.site?.name}`}</h5>
            <p className="mb-1">
              {`Address: ${item[0]?.site?.fullAddress?.streetAddress}, ${item[0]?.site?.fullAddress?.city}, ${item[0]?.site?.fullAddress?.state}`}{" "}
            </p>
            <p className="mb-1">{`Contact Phone: ${
              item[0]?.site?.phone || "-"
            }`}</p>
            <p className="mb-1">{`Police #: ${getPhoneNo(
              item[0]?.site?.policeDep
            )}`}</p>
            <p className="mb-1">{`Fire #: ${getPhoneNo(
              item[0]?.site?.fireDep
            )}`}</p>
            <p className="mb-0">{`Hospital #: ${getPhoneNo(
              item[0]?.site?.hospital
            )}`}</p>
          </div>

          <div className=" dashboardCardWrapper">
            {item?.map((value, index) => (
              <DashboardCard
                key={index}
                data={value}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
