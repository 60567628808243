import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Header } from './components/header/Header';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { Login } from './tabs/Login';
import { AppRoutes } from './AppRoutes';
import axios from 'axios';
import { useEffect, useState } from 'react';

function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  // Check authentication status on mount
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PRO_API}/user`, {
          withCredentials: true,
        });
        setUser(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Auth check failed:', err);
        redirectToLogin();
      }
    };
    checkAuth();
  }, []);

  const redirectToLogin = () => {
    window.location.replace(`${process.env.REACT_APP_PRO_API}/login`);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  // If user is authenticated, show the app
  if (user) {
    return (
      <div className="container-fluid">
        <Header />
        <AppRoutes />
      </div>
    );
  }

  // If not authenticated, show login
  return (
    <div className="d-flex bg-secondary" style={{ height: '100vh' }}>
      <Routes>
        <Route path="*" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;