import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiLink } from './apiConfig'

const initialState = {
  isLoading: false,
  readinessLogsLoading:false,
  lastRefresh: '',
  data: [],
  reportsByDateRange:[]
}
axios.defaults.withCredentials = true

// API FIND ALL REPORTS
export const apiFindAllReports = createAsyncThunk('reports/findAll', async () => {
  const reports = await axios
    .get(`${apiLink}/reports`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND ALL REPORTS:', err))
  return reports
})

// API ADD NEW REPORT
export const apiAddReport = createAsyncThunk('reports/create', async (newReport) => {
  const resData = await axios
    .post(`${apiLink}/reports/add`, newReport)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD NEW REPORT:', err))
  return resData
})

// API DEACTIVATE REPORT
export const apiDeactivateReport = createAsyncThunk('reports/deactivate', async ({ _id }) => {
  const resData = await axios
    .patch(`${apiLink}/reports/${_id}`, { active: false })
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API DEACTIVATE REPORT:', err))
  return resData
})

// API FIND REPORTS BY DATE RANGE
export const apiFindReportsByDateRange = createAsyncThunk('reports/findBy/dateRange', async ({fromDate, toDate,eventName}) => {
  const resData = await axios
    .post(`${apiLink}/reports/daterange`, { fromDate, toDate,eventName })
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND REPORTS BY DATE RANGE:', err))
  return resData
})

const reportsSlice = createSlice({
  name: 'reportsSlice',
  initialState,
  reducers: {
    addReport(state, action) {
      state.data.unshift(action.payload)
    },
    removeReport(state, action) {
      const { _id } = action.payload
      state.data = state.data.filter((x) => x._id !== _id)
    },
    clearReports(state, action) {
      return initialState
    },
  },
  extraReducers: (builder) =>
    builder
      // API FIND ALL REPORTS
      .addCase(apiFindAllReports.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiFindAllReports.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : state.data
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindAllReports.rejected, (state) => {
        state.isLoading = false
      })
      // API FIND REPORTS BY DATE RANGE
      .addCase(apiFindReportsByDateRange.pending, (state) => {
        state.readinessLogsLoading = true
      })
      .addCase(apiFindReportsByDateRange.fulfilled, (state, action) => {
        state.reportsByDateRange = action.payload ? action.payload : state.reportsByDateRange
        state.lastRefresh = new Date().toISOString()
        state.readinessLogsLoading = false
      })
      .addCase(apiFindReportsByDateRange.rejected, (state) => {
        state.readinessLogsLoading = false
      })
      // API ADD NEW REPORT
      .addCase(apiAddReport.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiAddReport.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.data.forEach((x) => {
          if (!x._id) {
            x._id = _id
          }
        })
        state.isLoading = false
      })
      .addCase(apiAddReport.rejected, (state) => {
        state.isLoading = false
      })
      // API DEACTIVATE REPORT
      .addCase(apiDeactivateReport.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiDeactivateReport.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(apiDeactivateReport.rejected, (state) => {
        state.isLoading = false
      }),
})

export const { addReport, clearReports, removeReport } = reportsSlice.actions

export default reportsSlice.reducer
