import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import { apiLink } from "./apiConfig";

const initialState = {
  imageKeys: [],
  signedUrls: {},
  expiry: {},
  isLoading: false,
};

// API FETCH SIGNED URL
export const apiFetchSignedUrl = createAsyncThunk(
  "images/fetchSignedUrl",
  async ({ imageKey }, { rejectWithValue }) => {
    if(!imageKey) return ""
    const response = await axios
      .get(`${apiLink}/get-presigned-url?objectKey=${imageKey}`)
      .then((res) => res.data)
      .catch((err) => {
        console.error("ERROR FETCHING SIGNED URL: ", err);
        return rejectWithValue(err.response?.data || "Fetch failed");
      });

    return { key: imageKey, url: response, expiry: Date.now() + 3600000 }; // 1 hour expiry
  }
);

// API REFRESH SIGNED URL
export const apiRefreshSignedUrl = createAsyncThunk(
  "images/refreshSignedUrl",
  async ({ imageKey }, { rejectWithValue }) => {
    if(!imageKey) return ""
    const response = await axios
      .get(`${apiLink}/get-presigned-url?objectKey=${imageKey}`)
      .then((res) => res.data)
      .catch((err) => {
        console.error("ERROR REFRESHING SIGNED URL: ", err);
        return rejectWithValue(err.response?.data || "Fetch failed");
      });

    return { key: imageKey, url: response, expiry: Date.now() + 3600000 }; // 1 hour expiry
  }
);

export const selectSignedUrl = createSelector(
  (state) => state.signedImageURLs?.signedUrls,
  (state) => state.signedImageURLs?.expiry,
  (_, imageKey) => imageKey, // Pass imageKey as a selector input
  (signedUrls, expiry, imageKey) => {
    if (!signedUrls[imageKey] || expiry[imageKey] < Date.now()) {
      return null;
    }
    return signedUrls[imageKey];
  }
);

export const selectHasExpired = createSelector(
  (state) => state.signedImageURLs?.expiry,
  (_, imageKey) => imageKey, // Pass imageKey as a selector input
  (expiry, imageKey) => !!expiry[imageKey] && expiry[imageKey] < Date.now()
);

const signedImageURLSlice = createSlice({
  name: "signedImageURLSlice",
  initialState,
  reducers: {
    addImageKey(state, action) {
      state.imageKeys.push(action.payload);
    },
    addSignedUrl(state, action) {
      state.signedUrls[action.payload.key] = action.payload.url;
      state.expiry[action.payload.key] = action.payload.expiry;
    },
    removeImageKey(state, action) {
      state.imageKeys = state.imageKeys.filter((key) => key !== action.payload);
      delete state.signedUrls[action.payload];
      delete state.expiry[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // API FETCH SIGNED URL
      .addCase(apiFetchSignedUrl.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(apiFetchSignedUrl.fulfilled, (state, action) => {
        state.signedUrls[action.payload.key] = action.payload.url;
        state.expiry[action.payload.key] = action.payload.expiry;
        state.isLoading = false;
      })
      .addCase(apiFetchSignedUrl.rejected, (state, action) => {
        state.isLoading = false;
      })
      // API REFRESH SIGNED URL
      .addCase(apiRefreshSignedUrl.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(apiRefreshSignedUrl.fulfilled, (state, action) => {
        state.signedUrls[action.payload.key] = action.payload.url;
        state.expiry[action.payload.key] = action.payload.expiry;
        state.isLoading = false;
      })
      .addCase(apiRefreshSignedUrl.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { addImageKey, addSignedUrl, removeImageKey } =
  signedImageURLSlice.actions;

export default signedImageURLSlice.reducer;
